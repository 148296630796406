<template>
  <div>
    myData
  </div>
</template>


<script>
export default {
  name : 'MyData'
}
</script>


<style scoped>

</style>